.filter-section {
    background-color: #f9f9f9; 
    padding: 15px; 
    border-radius: 8px;
    margin-bottom: 20px;
}

.filter-item {
    margin-bottom: 10px;
}

.filter-button-wrapper {
    text-align: right;
}