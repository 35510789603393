@use './variables';

#main {
    background-color: $white;
}

.row-m-1 {
    margin: 1rem;
    margin-bottom: 0;
}

.form-group {
    background-color: $grey-1;
    padding: 15px;
    border-radius: 4px;
    position: relative;
    margin: 10px 15px;
}

.form-sub-text {
    margin-bottom: 20px;
}

.form-button-row {
    display: flex;
    justify-content: flex-end;
}

.flex-div {
    display: flex;
    justify-content: flex-start;
}

.landing-page-card {
    border: 2px solid $itaa-gold;
    border-radius: 0.5rem;

    &:hover {
        background-color: $itaa-gold;
        color: $white;
    }

    .card-body {
        .btn-primary {
            &:hover {
                background-color: $itaa-sky !important;
                color: $itaa-blue !important;
            }
        }
    }

    .card-title {
        border-radius: 0.4rem 0.4rem 0 0;
    }

    .card-text {
        height: 3em;
        max-height: 3em;
        overflow: hidden;
    }

    .card-button {
        height: 2.8em;
        min-height: 2.8em;
        max-height: 2.8em;
        display: block !important;

        .btn-primary {
            border: none;
        }
    }
}

.card {
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* Adding a shadow */
    height: 100px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        /* Shadow on hover */
    }

    .card-body {
        padding: 10px;

        .card-title {
            font-weight: 600;
            margin-top: 5px;
        }

        .card-text {
            margin-bottom: 5px;
        }
    }
}

.custom-card {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 5px;
    transition: box-shadow 0.3s ease;
    border-color: $itaa-gold;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    &:hover {
        background-color: $itaa-gold;

        .card-text {
            color: $white;
        }
    }

    .card-title.h5 {
        color: $itaa-blue;

        @media (max-width: 1200px) {
            font-size: 1.05rem;
        }
    }

    .card-text {
        height: 3em;
        max-height: 3em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        text-overflow: ellipsis;
    }
}

.custom-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.custom-card .Landing-Page-Card-Title {
    font-size: 1em;
    color: #333;
    margin-bottom: 10px;
}

.custom-card .card-text {
    font-size: 1.1em;
    color: #666;
}

.Landing-Page-Card-Title {
    background-color: $itaa-blue;
    height: 40px;
    color: white;
    font-weight: bolder;

    p {
        margin: 10px;
        text-align: center;
    }
}

.Landing-Page-Card-Title-Admin {
    background-color: $itaa-gold;
    height: 40px;
    color: white;
    font-weight: bolder;

    p {
        margin: 10px;
        text-align: center;
    }
}

.lastDiv {
    margin-bottom: 5em;
}


.modal-body {
    margin: 'auto';
}

.beexcellentlabel {
    display: flex;
    justify-content: flex-end;

    span {
        border-radius: 3px;
        padding: 4px;
        color: $white;
    }

    .completed {
        background-color: $itaa-gold;
    }

    .none {
        background-color: $itaa-warning-red;
    }

    .processing {
        background-color: $grey-6;
    }
}

.info-text {
    background: #d9eafe;
    color: #437cbe;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #437cbe;
    border-radius: 5px;
    text-align: center;
    max-width: 40%;
    margin: 0 auto;
    margin-top: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    font-family: Arial, sans-serif;

    @media (max-width: 767px) {
        max-width: 90%;
    }
}

.categoryblock {
    display: block;
    font-size: 1.5rem;
    border: $itaa-blue 2px solid;
    border-radius: 7px;
    padding: 0.1em 0.5em;
    margin: 0.1em 0px ;
}

.pill-badge {
    background-color: $itaa-gold;
}

#selectAllTools {
    margin-bottom: 2.9em;
}

.filter-section {
    background-color: #f9f9f9; 
    padding: 15px; 
    border-radius: 8px;
    margin-bottom: 20px;
}

.filter-item {
    margin-bottom: 10px;
}

.filter-button-wrapper {
    text-align: right;
}
