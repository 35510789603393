@import './_variables';
@import './_base';
@import './_blocks';
@import './bar';
@import './_headers';
@import './_tables';
@import './_buttons';
@import './_sidebar';
@import './inputs';
@import './_loader';
@import './_texts';
@import './_filter';

:root {
    --option-background-color:#{$white};
    --option-text-color: #{$itaa-blue};
}

.pdf-viewer-embeded{
    width:100%;
    height:560px;
}

body {
    background: $grey-2;
}
.page-wrap {
    min-height: 80vh;
    color: $itaa-blue;
}
.link-tag {
    color: $grey-2;
    text-decoration: underline;
}
.link-group-Landing-page{
    margin-left: 20px;
}


.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: $itaa-hover-blue;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: $itaa-hover-blue;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.dropdown-item:hover {
    color: $white;
    background-color: $itaa-sea;
}

a, button, input[type="submit"], input[type="button"], .clickable {
    cursor: pointer;
}

/* General padding and margin adjustments */
.container {
    padding: 20px;
}

/* Filter section styling */
.filter-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Filter badge styling */
.badge-section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.filter-badge {
    font-size: 14px;
    padding: 8px 12px;
    display: inline-flex;
    align-items: center;
}

.close-icon {
    margin-left: 8px;
    cursor: pointer;
}

/* Table styling */
.table-section thead {
    background-color: #f8f9fa;
}

.table-section th, .table-section td {
    text-align: center;
    vertical-align: middle;
}

/* Pagination styling */
.pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.pagination .page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
}

/* Responsive styling */
@media (max-width: 768px) {
    .filter-section {
        flex-direction: column;
    }
    
    .badge-section {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .table-section th, .table-section td {
        font-size: 14px;
    }
}
