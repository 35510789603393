@use './variables';

a {
    text-decoration: none !important;
}

#navbar {
    padding: 0.8rem !important;
}

.navbar {
    height: 60px;
    padding: 10px;
    background:$white; 
    border-radius: 0;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    z-index: 2; 
    width: 100%;
    top: 0;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.navbar-icon {
    font-size: 1.5em !important;
    color: $itaa-rust;
    margin-right: 0.2em;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
.wrapper {
    display: flex;
    height: 100vh; /* Make the layout take full viewport height */
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: $white;
}

a[data-toggle="collapse"] {
    position: relative;
    padding-right: 20px !important;
}

.dropdown-toggle{
    white-space: unset !important;
}

input {
    margin-right: 2px !important;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: $white;
}

a.download {
    background: $itaa-gold !important;
    color: $white !important; 
}

a.download:hover {
    background: $itaa-blue !important;
    color: $white !important;
}

a.article {
    background: $itaa-gold !important;
    color: $white !important; 
}

a.article:hover {
    background: $itaa-blue !important;
    color: $white !important;
}

/* FOOTER */
.footersidenav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    border-top: 1px solid #E6E6E6;
}

.footersidenav a {
    color: $itaa-blue;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar.active .footersidenav {
        margin-left: 0 !important;
        max-width: none !important;
    }
    #sidebar .mainMenu .main-menu-item {
        max-width: none !important;
    }
    #sidebar .sub-sub-menu-item {
        max-width: none !important;
    }
    #sidebar .mainMenu .sub-menu-item {
        max-width: none !important;
    }
    .logo {
        display: flex;
        align-items: center;
    }
    #mobileSidebarCollapse {
        display: block;
        margin-left: auto; 
        margin-top: 10px;
        margin-right: 10px;
    }
    #mobileSidebarCollapse span {
        display: none;
    }
    #sidebar {
        margin-left: -250px;
        box-shadow: none;
    }
    #sidebar.active {
        width: 100%;
        max-width: none;
        min-width: 0;
        margin-left: 0;
    }
    #sidebar.active + #content {
        display: none;
    }
    #sidebarCollapse span {
        display: none;
    }
}

@media (max-width: 767px) {
    .text-white:last-child {
        display: none;
    }
}

@media (min-width: 769px) {
    #mobileSidebarCollapse {
        display: none;
    }
}