@use './variables';

.btn-primary {
    background-color: $itaa-blue;
    padding: 0 22.2222222222px;
    color: $white;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    align-items: center;
    border-radius: 3px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 600;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: .0892857143em;
    text-transform: uppercase;
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover {
        background-color: $itaa-active-blue !important;
        color: $white !important;
    }

    a {
        color: $white;
    }
}
.btn-home-Admin {
    background-color: $itaa-blue;
    padding: 0 22.2222222222px;
    color: $white;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    align-items: center;
    border-radius: 3px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 600;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: .0892857143em;
    text-transform: uppercase;
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    background-color: $itaa-gold;

    &:hover {
        background-color: $itaa-active-blue !important;
        color: $white !important;
    }

    a {
        color: $white;
    }
}

.itaa-data-form-row {
    display: flex;
    justify-content: space-between;
}

.btn-error{
    
        border-radius: 4px;
        font-size: 14px;
        border-color: $itaa-gold;
        color: white;
        font-family: sans-serif;
        text-transform: uppercase;
        height: 40px;
        border-style: solid;
        color: $itaa-gold;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1.25px;
        text-decoration: none solid rgb(255, 255, 255);
        background-color: white;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}    
.btn-error:hover {
        background-color: $itaa-gold; 
        color: white;
    }
    
    .btn-error i:hover {
        color: initial; 
        
    }

.btn-eloket {
    background-color: $itaa-gold;
    padding: 0 11px;
    color: $white;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    align-items: center;
    border-radius: 3px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 600;

    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: .0892857143em;
    text-transform: uppercase;
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    user-select: none;
    vertical-align: middle;

    &:disabled {
        background-color: $itaa-blue;
        color: rgb(150, 152, 154);
    }

    &:hover, &:active {
        background-color: $itaa-hover-gold !important;
        color: $white !important;
    }

    a {
        color: $white;
    }
}

.estox-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $grey-2;
    color: $grey-7;
    padding: 10px; 
    border: none;
    cursor: pointer;
    margin-bottom: 0.5em;
    white-space: normal; 
    overflow: hidden; 
    word-wrap: break-word; 
    text-transform: none;

    h4 {
      margin: 0;
      padding-bottom: 10px; 
      font-weight: bold;
    }
  
    p {
      margin: 0;
      font-weight: normal;
    }
  
    &:hover {
      background-color: $itaa-blue;
    }
  }

  .button-sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-eloket {
        width: 90%;
        margin-bottom: 0.2em;
    }
  }

@media only screen and (max-width: 768px) {
    .form-button-row {
        display: flex;
        flex-direction: column;
    }

    .form-button-row button {
        margin-bottom: 10px; /* voeg ruimte toe tussen de knoppen */
        width: 100%; /* neem de volledige breedte van de container in beslag */
    }
    .money-laundraing-btn, .itaa-data-form-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
        max-width: 98%;   
    }
    .button-container-data {
        display: flex;
        gap: 10px; /* Voeg ruimte toe tussen de knoppen, pas aan indien nodig */
    }
}