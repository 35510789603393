@use './variables';

.loader-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(150, 150, 150, 0.5);
    /* Transparent gray background */
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it's on top of other elements */
}

.loader {
    border: 4px solid $itaa-gold;
    border-top: 4px solid $itaa-blue;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 0.7s linear infinite;
}

.loader-small {
    margin-top: -20px;
    margin-bottom: 10px;
    border: 4px solid $itaa-gold;
    border-top: 4px solid $itaa-blue;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}