//colors
$itaa-blue: #142F4B;
$itaa-gold: #d49c5f;
$itaa-pine: #0f5256;
$itaa-sky: #9cd3e2;
$itaa-sea: #6d8ead;
$itaa-sand: #ffdf99;
$itaa-beige: #c1b384;
$itaa-rust: #ef7641;

$itaa-active-blue: #4C6176;
$itaa-hover-blue: #263F59;
$itaa-hover-gold: #B97E36;
$itaa-disabled-gold: #dbac79;

$white: #ffffff;
$grey-1: #f4f4f2;
$grey-2: #dbdbdb;
$grey-3: #c4c4c4;
$grey-4: #b0b0b0;
$grey-5: #a1a1a1;
$grey-6: #7a7a7a;
$grey-7: #5b5b5b;
$grey-8: #333333;
$grey-9: #272727;
$black: #000000;

$itaa-warning-red: #b00020;