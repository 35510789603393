@use './variables';

.logo {
    width: 100%;
    text-align: center;
    img {
        margin:10px;
        width: 43%;
    }
}
.nav{
    position: fixed; 
    top: 0; 
    left: 0; 
    margin-right: 300px;
}
.dropdown-toggle{
    display: block;
}

#sidebar {
    background-color: $itaa-blue;
    color: $white;
    min-width: 250px;
    max-width: 250px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 100vh; 
    .mainMenu {
        margin-top: 40px;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 0.75rem;
        max-height: 70vh;
        
        scrollbar-color: $itaa-gold $itaa-blue; 
        &::-webkit-scrollbar-track{
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: $itaa-blue;
        }
        &::-webkit-scrollbar
        {
            width: 6px;
            background-color: $itaa-blue;
        }
        &::-webkit-scrollbar-thumb
        {
            background-color: $itaa-gold;
        }
        &::-webkit-scrollbar-button {
           display: none;
        }
        scrollbar-width: 6px;
        
        
        .main-menu-item {
            min-width: 240px;
            max-width: 240px;
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 10px;
            color: $white;
            padding: 4px 8px;
            
            a {
                color: $white;
                padding: 2px;
                font-size: 15px;

                i {
                    margin-right: 10px;
                }
            }

            &.active{
                border-radius: 4px;
                background-color: $itaa-active-blue;
            }

            &:hover {
                border-radius: 4px;
                background-color: $itaa-hover-blue;
            }
        }

        
            .sub-menu-item {
                margin-top: 2px;
                max-width: 216px;
                min-width: 216px;
                margin-left: 18px;
                margin-right: 11px;
                color: $white;
                padding: 4px 8px;
                display: block;

                a {
                    color: $white;
                    padding: 2px;
    
                    i {
                        margin-right: 10px;
                    }
                }

                &.active{
                    border-radius: 4px;
                    background-color: $itaa-active-blue;
                }

                &:hover {
                    border-radius: 4px;
                    background-color: $itaa-hover-blue;
                }
            }
    }



    .sub-sub-menu-item {
        margin-top: 2px;
        margin-left: 25px;
        margin-right: 25px; /* Aanpassing: verander deze waarde naar wat je nodig hebt */
        color: $white;
        padding: 4px 8px;
        display: block;
    
        a {
            display: block;
            width: 100%;
            color: $white;
            padding: 2px;
            i {
                margin-right: 10px;
            }
        }
    
        &.active {
            border-radius: 4px;
            background-color: $itaa-active-blue;
        }
    
        &:hover {
            border-radius: 4px;
            background-color: $itaa-hover-blue;
        }
    }
    


    ul {
        min-width: 240px;
        max-width: 240px;
        margin-left: 5px;
        margin-right: 5px;

        li {
            color: $white;
            padding: 4px 8px;

            a {
                color: $white;
                padding: 2px;

                i {
                    margin-right: 10px;
                }
            }

            ul {
                background-color: $itaa-blue;
                min-width: 200px;
                max-width: 200px;

                li {
                    background-color: $itaa-blue;
                    a {
                        background-color: $itaa-blue;
                    }
                }

                li.active {
                    background-color: $itaa-active-blue;
                    a {
                        background-color: $itaa-active-blue;
                    }
                }
            }
        }

        li.active {
            border-radius: 4px;
            background-color: $itaa-active-blue;
            a {
                background-color: $itaa-active-blue;
            }

            ul {
                background-color: $itaa-blue;
                min-width: 200px;
                max-width: 200px;

                li {
                    background-color: $itaa-blue;
                    a {
                        background-color: $itaa-blue;
                    }
                }

                li.active {
                    background-color: $itaa-active-blue;
                    a {
                        background-color: $itaa-active-blue;
                    }
                }
            }
        }
    }
}

.footersidenav {
    font-size: 0.7rem;
    text-align: center;
    padding: 0;
    padding-top: 20px;
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    border:none;
    min-width: 230px;
    max-width: 230px;
    margin-left: 8px;
}


.backhome-btn{
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    background-color: $itaa-blue;
    border-color: $itaa-gold;
    color: $white;
    font-family: sans-serif;
    text-transform: uppercase;
    height: 40px;
    border-style: solid;
    color: $itaa-gold;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    width: 100%;
    text-decoration: none solid rgb(255, 255, 255);

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.backhome-btn:hover {
    background-color: $itaa-gold; 
    color: $white;
}

.backhome-btn i:hover {
    color: initial; 
    
}

.backhome-btn:hover i {
    color: $white;
}

.language-button{
    background-color:  $itaa-blue;
    color: $white;
    border: none;
    margin-right: 5px;
    width: 40px;
    border-radius: 3px;
    font-weight: bold;

    &:hover, &.active {
        background-color:$itaa-sky;
        color: $itaa-blue;
    }
}

.language-button-section{
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    background-color: $itaa-blue;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-decoration: none solid $white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#btn-language-sperator{
    font-size: 14px;
    font-weight: lighter;
    margin-right: 6px;
    border-radius: 3px;
}

.ref-link{
    margin-top: 10px;
    background-color: $itaa-blue;
    color: $grey-6;
    border: none;
    font-size: 11px;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    #sidebar .mainMenu {
      max-height: 400px;

    }

    .backhome-btn{
     max-width: 90%;
    }

    .main-menu-item a {
        font-weight: bold;
        font-size: 22px;
    }
    .sub-sub-menu-item a {
        font-weight: bold;
        font-size: 15px;
    }
    .sub-menu-item  {
        font-weight: bold;
        font-size: 17px;
        max-width: 100%;
    }

}