@use './variables';

.text-h1 {
    color: $itaa-blue;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 3.375rem;
    letter-spacing: .00625rem;
    margin-left: 15px;
}
.legalPerson-header-h1{
    color: $itaa-blue;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 3.375rem;
    letter-spacing: .00625rem;
    margin-left: 15px;
    color: $itaa-blue;
    list-style: lin;
}
.legalPerson-header-h1:hover{
    
    color: rgb(6, 133, 252);
}

.text-h2 {
    color: $itaa-sea;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: .00625rem;
    margin-left: 20px;
}

.nomargin {
    margin:0px;
}

.menu {
    display: flex;
    align-items: center;
}

.menu .btn-eloket {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

@media only screen and (max-width: 768px) {
    .text-h1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 2rem;
        letter-spacing: normal;
        margin-left: 15px;
    }

    .text-h2 {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.2rem;
        letter-spacing: normal;
        margin-left: 15px;
    }
}