@use './variables';

input[type=checkbox], input[type=radio] {
  accent-color: $itaa-blue;
}

#dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid $itaa-blue;
  background-color: #f5f5f5;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  outline: none !important;
  caret-color: $itaa-gold;

  &:focus {
    border-color: $itaa-gold;
    box-shadow: 0 0 0 0;
  }

  &:not(:focus):not([value=""]) {
    border-color: $itaa-blue;
  }
}

.masked-kbo-input:disabled {
    opacity: 0.5;
}

.error-field {
    display: block;
    margin-top: -20px;
    color: rgb(200, 94, 94);
}

.form-group {
     
    .form-header {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    .form-row {
      .form-col {
        .control-label {
          position: relative;
          top: -62px;
          left: 8px;
          background-color: #f5f5f5;
          padding-right: 5px;
          padding-left: 2px;
          color: $itaa-blue;
          font-size: 14px;
            &.text-area {
              top: -85px;
            }
        }

          .required {
            color: $itaa-blue;
          }
  
          .controls {
            position: relative;

            &:focus-within {
              .control-label {
                color: $itaa-gold;
              }

              input, textarea {
                border-color: $itaa-gold;
              }
            }

            input, textarea {
              width: 100%;
              padding: 10px;
              border: 1px solid $itaa-blue;
              background-color: #f5f5f5;
              border-radius: 5px;
              transition: border-color 0.3s ease;
              outline: none !important;
              caret-color: $itaa-gold;
              display: block;
              &:focus {
                border-color: $itaa-gold;
                box-shadow: 0 0 0 0;
              }
  
              &:not(:focus):not(:valid) {
                border-color: $itaa-blue;
              }
            }
          }

          .list-group {
            position: relative;
            top: -24px;
          }
      }

      .form-button-row {
        margin-top: 12px;
      }
    }
  }

  .read-only-input {
    border-color: rgb(197, 193, 193) !important;
    color:  rgb(169, 168, 168) ;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .form-row .form-col .control-label {
        font-size: 10px;
    }
}

.list-group {
  cursor: pointer;
}

.list-group-item-action.selected {
  background-color: $itaa-sky;
}

.form-control.disabled {
  background-color: $grey-2;
}

